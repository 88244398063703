import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
        >
          <CFView pt="3%" textCenter w="100%" bg="rgba(250,250,250,.65)">
            <CFImage
              w="95%"
              src={mobileHeroText}
              alt="Wasabi Sushi & Grill hero text"
              zIndex={98}
            />
            <CFView mt="10px" mb="15px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          maxWidth="1400px"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <CFView
            column
            justifyCenter
            alignStart
            h="100%"
            pr="35px"
            pl="65px"
            bg="rgba(250,250,250,.5)"
          >
            <CFView column justifyStart alignStart>
              <CFImage
                w="55vw"
                maxWidth="650px"
                src={heroText}
                alt="Wasabi Sushi & Grill hero text"
                zIndex={98}
              />
            </CFView>
            <CFView w="30vw" maxWidth="350px" mv="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
